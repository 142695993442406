// src/features/entry.js

const theme = {
    primaryColor: '#fff',//'#e0f7fa',
    secondaryColor: '#ffe0b2',
  };
  
  function getFeatures(t) {
    const features1 = [
      {
        type: 'hoverImageFlip',
        title: t("feature.diary"),
        description: t("feature.diary-description"),
        image: t("feature.images.diary-1"),
        hoverImage: t("feature.images.diary-2"),
        customStyles: { backgroundColor: theme.primaryColor},
        heights: { lg: "800px", md: "700px", sm: "650px" }
      },
      {
        type: 'hoverImageFlip',
        title: t("feature.plan"),
        description: t("feature.plan-description"),
        image: t("feature.images.plan-1"),
        hoverImage: t("feature.images.plan-2"),
        customStyles: { backgroundColor: theme.primaryColor},
        heights: { lg: "800px", md: "700px", sm: "650px" }
      },
      {
        type: 'hoverImageFlip',
        title: t("feature.smart"),
        description: t("feature.smart-description"),
        image: t("feature.images.smart-1"),
        hoverImage: t("feature.images.smart-2"),
        customStyles: { backgroundColor: theme.primaryColor},
        heights: { lg: "800px", md: "700px", sm: "650px" }
      }
    ];
  
    const features2 = [
      {
        type: 'fallingText',
        topLeftText: t("feature.diary-feature-title"),
        text: t("feature.diary-feature-description"),
        customStyles: { backgroundColor: theme.primaryColor},
        chineseCharsPerLine: 12,
        englishWordsPerLine: 6,
        heights: { lg: "800px", md: "800px", sm: "750px" } 
      },
      {
        type: 'image',
        title: t("feature.diary-feature1-title"),
        description: t("feature.diary-feature1-description"),
        image: t("feature.images.diary-feature1-cn"),
        imageWidth: "65%",
        customStyles: { backgroundColor: theme.primaryColor },
        heights: { lg: "800px", md: "700px", sm: "600px" }
      },
      {
        type: 'image',
        title: t("feature.diary-feature2-title"),
        description: t("feature.diary-feature2-description"),
        image: t("feature.images.diary-feature2-cn"),
        imageWidth: "80%",
        customStyles: { backgroundColor: theme.primaryColor},
        heights: { lg: "800px", md: "700px", sm: "700px" }
      },
      {
        type: 'image',
        title: t("feature.diary-feature3-title"),
        description: t("feature.diary-feature3-description"),
        image: t("feature.images.diary-feature3-cn"),
        imageWidth: "65%",
        customStyles: { backgroundColor: theme.primaryColor},
        heights: { lg: "800px", md: "700px", sm: "600px" }
      },
      {
        type: 'image',
        title: t("feature.diary-feature4-title"),
        description: t("feature.diary-feature4-description"),
        image: t("feature.images.diary-feature4-cn"),
        imageWidth: "65%",
        customStyles: { backgroundColor: theme.primaryColor},
        heights: { lg: "800px", md: "700px", sm: "600px" }
      }
    ];
  
    const features3 = [
      {
        type: 'image',
        title: t("feature.plan-feature1-title"),
        description: t("feature.plan-feature1-description"),
        image: t("feature.images.plan-feature1-cn"),
        imageWidth: "65%",
        customStyles: { backgroundColor: theme.primaryColor},
        heights: { lg: "800px", md: "700px", sm: "600px" }
      },
      {
        type: 'image',
        title: t("feature.plan-feature2-title"),
        description: t("feature.plan-feature2-description"),
        image: t("feature.images.plan-feature2-cn"),
        imageWidth: "80%",
        customStyles: { backgroundColor: theme.primaryColor},
        heights: { lg: "800px", md: "700px", sm: "700px" }
      },
      {
        type: 'fullImage',
        title: t("feature.plan-feature-title"),
        description: t("feature.plan-feature-description"),
        image: t("feature.images.plan-feature-1-cn"),
        customStyles: { height: "800px" },
        heights: { lg: "800px", md: "700px", sm: "600px" }
      },
      {
        type: 'image',
        title: t("feature.plan-feature3-title"),
        description: t("feature.plan-feature3-description"),
        image: t("feature.images.plan-feature3-cn"),
        imageWidth: "80%",
        customStyles: { backgroundColor: theme.primaryColor},
        heights: { lg: "800px", md: "700px", sm: "700px" }
      },
      {
        type: 'image',
        title: t("feature.plan-feature4-title"),
        description: t("feature.plan-feature4-description"),
        image: t("feature.images.plan-feature4-cn"),
        imageWidth: "65%",
        customStyles: { backgroundColor: theme.primaryColor},
        heights: { lg: "800px", md: "700px", sm: "600px" }
      }
      /*
      {
        type: 'video',
        title: t("feature.simplePersonalHealthy"),
        description: t("feature.description4"),
        video: "/videos/feature1.mp4",
        customStyles: { backgroundColor: "#ffecb3", height: "650px" }
      }*/
    ];
  
    const features4 = [
      {
        type: 'image',
        title: t("feature.smart-feature-title"),
        description: t("feature.smart-feature-description"),
        image: t("feature.images.smart-feature-cn"),
        imageWidth: "90%",
        customStyles: { backgroundColor: theme.primaryColor},
        heights: { lg: "800px", md: "700px", sm: "600px" }
      },
      {
        type: 'image',
        title: t("feature.smart-feature1-title"),
        description: t("feature.smart-feature1-description"),
        image: t("feature.images.smart-feature1-cn"),
        imageWidth: "60%",
        customStyles: { backgroundColor: theme.primaryColor},
        heights: { lg: "800px", md: "700px", sm: "600px" }
      },
      {
        type: 'gradientText',
        topLeftText: t("feature.smart-feature2-title"),
        centerText: t("feature.smart-feature2-description"),
        customStyles: { height: "800px" },
        heights: { lg: "800px", md: "700px", sm: "600px" }
      }
    ];
  
    const features5 = [
      {
        type: 'imageButton',
        description: t("feature.appstore-description"),
        buttonImage: "/images/download.svg",
        buttonUrl: "https://apps.apple.com/us/app/新煮意/id6463604621",
        customStyles: { backgroundColor: theme.primaryColor },
        heights: { lg: "400px", md: "400px", sm: "400px" }
      }
    ];
  
    const featureWidths1 = [
      { lg: 4, md: 8, sm: 12 },
      { lg: 4, md: 8, sm: 12 },
      { lg: 4, md: 8, sm: 12 }
    ];
  
    const featureWidths2 = [
      { lg: 12, md: 8, sm: 12 },
      { lg: 8, md: 8, sm: 12 },
      { lg: 4, md: 8, sm: 12 },
      { lg: 6, md: 8, sm: 12 },
      { lg: 6, md: 8, sm: 12 }
    ];
  
    const featureWidths3 = [
      { lg: 8, md: 8, sm: 12 },
      { lg: 4, md: 8, sm: 12 },
      { lg: 12, md: 8, sm: 12 },
      { lg: 4, md: 8, sm: 12 },
      { lg: 8, md: 8, sm: 12 }
    ];
  
    const featureWidths4 = [
      { lg: 12, md: 8, sm: 12 },
      { lg: 6, md: 8, sm: 12 },
      { lg: 6, md: 8, sm: 12 }
    ];
  
    const featureWidths5 = [
      { lg: 12, md: 12, sm: 12 }
    ];
  
    return {
      features1,
      features2,
      features3,
      features4,
      features5,
      featureWidths1,
      featureWidths2,
      featureWidths3,
      featureWidths4,
      featureWidths5,
      theme
    };
  }
  
  export default getFeatures;