// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Contact from './components/Contact';
import Privacy from './components/Privacy';
import TermsOfUse from './components/TermsOfUse';
import FeatureList from './components/FeatureList';
import './App.css';
import { ThemeProvider } from './components/ThemeContext';
import './i18n'; // 确保加载i18n配置
import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation } from 'react-i18next';
import getFeatures from './features/entry';

const AppContent = () => {
  const { t } = useTranslation();
  const {
    features1,
    features2,
    features3,
    features4,
    features5,
    featureWidths1,
    featureWidths2,
    featureWidths3,
    featureWidths4,
    featureWidths5,
    theme
  } = getFeatures(t);

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <FeatureList title={t("feature.functions")} features={features1} featureWidths={featureWidths1} customTitleClass="custom-title-class1" />
        <FeatureList title={t("feature.diary-feature")} features={features2} featureWidths={featureWidths2} customTitleClass="custom-title-class2" />
        <FeatureList title={t("feature.plan-feature")} features={features3} featureWidths={featureWidths3} customTitleClass="custom-title-class3" />
        <FeatureList title={t("feature.smart-feature")} features={features4} featureWidths={featureWidths4} customTitleClass="custom-title-class4" />
        <FeatureList title={("")} features={features5} featureWidths={featureWidths5} customTitleClass="custom-title-class4" />
      </div>
    </ThemeProvider>
  );
};

function App() {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <div className="App">
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/termsofuse" element={<TermsOfUse />} />
      </Routes>
      {location.pathname !== '/about' && location.pathname !== '/contact' && location.pathname !== '/privacy' && location.pathname !== '/termsofuse' && <AppContent />}
    </div>
  );
}

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;