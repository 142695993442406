// src/components/ImageButtonFeature.js
import React from 'react';
import './ImageButtonFeature.css';

function ImageButtonFeature({ description, buttonImage, buttonUrl, customStyles }) {
  return (
    <div className="image-button-feature" style={customStyles}>
      <p className="feature-description">{description}</p>
      <a href={buttonUrl} target="_blank" rel="noopener noreferrer">
        <img src={buttonImage} alt="Button" className="button-image" />
      </a>
    </div>
  );
}

export default ImageButtonFeature;