// src/components/TermsOfUse.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import './TermsOfUse.css';

function TermsOfUse() {
  const { t } = useTranslation();

  return (
    <div className="container mt-5 termsofuse-container">
      <h1>{t('termsofuse.title')}</h1>
      <h2>{t('termsofuse.section1.title')}</h2>
      <p>{t('termsofuse.section1.content')}</p>
      <h2>{t('termsofuse.section2.title')}</h2>
      <p>{t('termsofuse.section2.content')}</p>
      <h2>{t('termsofuse.section3.title')}</h2>
      <p>{t('termsofuse.section3.content')}</p>
      <h2>{t('termsofuse.section4.title')}</h2>
      <p>{t('termsofuse.section4.content')}</p>
      <h2>{t('termsofuse.section5.title')}</h2>
      <p>{t('termsofuse.section5.content')}</p>
      <h2>{t('termsofuse.section6.title')}</h2>
      <p>{t('termsofuse.section6.content')}</p>
      <h2>{t('termsofuse.section7.title')}</h2>
      <p>{t('termsofuse.section7.content')}</p>
      <h2>{t('termsofuse.section8.title')}</h2>
      <p>{t('termsofuse.section8.content')}</p>
    </div>
  );
}

export default TermsOfUse;