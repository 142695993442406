// src/index.js
import React from 'react';
import { createRoot } from 'react-dom/client';
import AppWrapper from './App';
import './i18n';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(<AppWrapper />);