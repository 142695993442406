// src/components/GradientTextFeature.js
import React from 'react';
import './GradientTextFeature.css';

function GradientTextFeature({ topLeftText, centerText, customStyles }) {
  return (
    <div className="gradient-text-feature" style={customStyles}>
      <div className="top-left-text">{topLeftText}</div>
      <div className="center-text">{centerText}</div>
    </div>
  );
}

export default GradientTextFeature;