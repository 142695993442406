// src/components/FeatureList.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import Feature from './Feature';
import VideoFeature from './VideoFeature';
import HoverFeature from './HoverFeature';
import HoverImageFlip from './HoverImageFlip';
import FallingTextFeature from './FallingTextFeature';
import ShiningDotFeature from './ShiningDotFeature';
import FullImageFeature from './FullImageFeature';
import GradientTextFeature from './GradientTextFeature';
import ImageButtonFeature from './ImageButtonFeature'; // Import the new component
import './FeatureList.css';

function FeatureList({ title, features, featureWidths, customTitleClass }) {
  const { t } = useTranslation();

  const getResponsiveHeight = (heights) => {
    const width = window.innerWidth;
    if (width >= 992) {
      return heights.lg;
    } else if (width >= 768) {
      return heights.md;
    } else {
      return heights.sm;
    }
  };

  return (
    <div className="feature-list-container">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2 className={`feature-list-title ${customTitleClass}`}>{t(title)}</h2>
          </div>
        </div>
        <div className="row">
          {features.map((feature, index) => {
            let FeatureComponent;
            switch (feature.type) {
              case 'video':
                FeatureComponent = VideoFeature;
                break;
              case 'hover':
                FeatureComponent = HoverFeature;
                break;
              case 'hoverImageFlip':
                FeatureComponent = HoverImageFlip;
                break;
              case 'fallingText':
                FeatureComponent = FallingTextFeature;
                break;
              case 'shiningDot':
                FeatureComponent = ShiningDotFeature;
                break;
              case 'fullImage':
                FeatureComponent = FullImageFeature;
                break;
              case 'gradientText':
                FeatureComponent = GradientTextFeature;
                break;
              case 'imageButton':
                FeatureComponent = ImageButtonFeature;
                break;
              default:
                FeatureComponent = Feature;
                break;
            }
            const heightStyle = {
              height: getResponsiveHeight(feature.heights)
            };
            return (
              <div
                key={index}
                className={`col-lg-${featureWidths[index].lg} col-md-${featureWidths[index].md} col-sm-${featureWidths[index].sm} mb-4`}
                style={heightStyle}
              >
                <FeatureComponent
                  title={t(feature.title)}
                  description={t(feature.description)}
                  image={feature.image}
                  hoverImage={feature.hoverImage}
                  video={feature.video}
                  text={feature.text}
                  charsPerLine={feature.charsPerLine}
                  imageWidth={feature.imageWidth}
                  customStyles={{ ...feature.customStyles, ...heightStyle }}
                  topLeftText={feature.topLeftText}
                  centerText={feature.centerText}
                  chineseCharsPerLine={feature.chineseCharsPerLine}
                  englishWordsPerLine={feature.englishWordsPerLine}
                  buttonImage={feature.buttonImage} // Add buttonImage prop
                  buttonUrl={feature.buttonUrl} // Add buttonUrl prop
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default FeatureList;