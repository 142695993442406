import React, { useState, useRef, useEffect } from 'react';
import './FallingTextFeature.css';

function FallingTextFeature({ topLeftText, text, customStyles, chineseCharsPerLine, englishWordsPerLine }) {
  const [visibleItems, setVisibleItems] = useState([]);
  const [hasFallen, setHasFallen] = useState(false);
  const featureRef = useRef(null);

  // 分割文本为单个字或单个单词
  const splitText = (text) => {
    const chineseRegex = /[\u4e00-\u9fa5]/;
    if (chineseRegex.test(text)) {
      return text.split('');
    } else {
      return text.split(/\s+/); // 按空格分割英文单词
    }
  };

  const items = splitText(text);
  const timeouts = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !hasFallen) {
          // 设置定时器以逐个字或单词掉落
          items.forEach((item, i) => {
            const timeout = setTimeout(() => {
              setVisibleItems((prev) => {
                const updatedItems = [...prev, item];
                return updatedItems;
              });
            }, i * 100);
            timeouts.current.push(timeout);
          });
          setHasFallen(true);
        }
      },
      { threshold: 0.5 }
    );

    if (featureRef.current) {
      observer.observe(featureRef.current);
    }

    return () => {
      observer.disconnect();
      //timeouts.current.forEach(clearTimeout);
      timeouts.current = [];
    };
  }, [items, hasFallen]);

  const renderItems = () => {
    const chineseRegex = /[\u4e00-\u9fa5]/;
    const charsPerLine = chineseRegex.test(text) ? chineseCharsPerLine : englishWordsPerLine;
    const rows = [];
    for (let i = 0; i < visibleItems.length; i += charsPerLine) {
      const rowItems = visibleItems.slice(i, i + charsPerLine);
      rows.push(
        <div key={i} className="falling-text-row">
          {rowItems.map((item, index) => (
            <span key={index} className="falling-text-item" style={{ animationDelay: `${(i + index) * 0.1}s` }}>
              {item}
            </span>
          ))}
        </div>
      );
    }
    return rows;
  };

  return (
    <div ref={featureRef} className="falling-text-feature" style={customStyles}>
      <div className="top-left-text">{topLeftText}</div>
      <div className="falling-text-content">{renderItems()}</div>
    </div>
  );
}

export default FallingTextFeature;