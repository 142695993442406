import React, { useState } from 'react';
import './HoverImageFlip.css';

function HoverImageFlip({ title, description, image, hoverImage, customStyles }) {
  const [flipped, setFlipped] = useState(false);

  const handleMouseEnter = () => {
    setFlipped(true);
  };

  const handleMouseLeave = () => {
    setFlipped(false);
  };

  return (
    <div
      className={`hover-image-flip ${flipped ? 'flip' : ''}`}
      style={customStyles}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="hover-image-flip-content">
        <h3 className="hover-image-flip-title">{title}</h3>
        <p className="hover-image-flip-description">{description}</p>
      </div>
      <div className="hover-image-flip-image-container">
        <img src={image} alt={title} className="hover-image-flip-image front" />
        <img src={hoverImage} alt={title} className="hover-image-flip-hover-image back" />
      </div>
    </div>
  );
}

export default HoverImageFlip;