// src/components/Navbar.js
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Navbar.css';

function Navbar() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          <img src="/newtrition.png" alt={t('navbar.brand')} className="navbar-logo" />
        </Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link className="nav-link" to="/">{t('navbar.home')}</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/termsofuse">{t('navbar.termsofuse')}</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/privacy">{t('navbar.privacy')}</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/contact">{t('navbar.contact')}</Link>
            </li>
          </ul>
          {/*
          <div className="d-flex">
            <button className="btn btn-outline-secondary me-2" onClick={() => changeLanguage('en')}>English</button>
            <button className="btn btn-outline-secondary" onClick={() => changeLanguage('zh')}>中文</button>
          </div>
          */}
        </div>
      </div>
    </nav>
  );
}

export default Navbar;