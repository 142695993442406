// src/components/Contact.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import './Contact.css';

function Contact() {
  const { t } = useTranslation();

  return (
    <div className="container mt-5 contact-container">
      <h1>{t('contact.title')}</h1>
      <p>{t('contact.intro')}</p>
      <p>
        {t('contact.email')}: <a href="mailto:support@ainewtrition.com">support@ainewtrition.com</a>
      </p>
      <p>
        {t('contact.twitter')}: <a href="https://twitter.com/ainewtrition" target="_blank" rel="noopener noreferrer">https://twitter.com/ainewtrition</a>
      </p>
      <p className="wechat-section">
        {t('contact.wechat')}: {t('contact.wechatName')} <img src="/wechat.png" alt="QR Code" className="qr-code" />
      </p>
    </div>
  );
}

export default Contact;