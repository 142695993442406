// src/components/FullImageFeature.js
import React from 'react';
import './FullImageFeature.css';

function FullImageFeature({ title, description, image, customStyles }) {
  return (
    <div className="full-image-feature" style={{ ...customStyles, backgroundImage: `url(${image})` }}>
      <div className="text-container">
        <h3 className="feature-title">{title}</h3>
        <p className="feature-description">{description}</p>
      </div>
    </div>
  );
}

export default FullImageFeature;