import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Hero.css';
import { useTranslation } from 'react-i18next';

function Hero({ buttonSize = 'medium', buttonImage, buttonLink, imageWidth = '50%', imageHeight = 'auto' }) {
  const { t } = useTranslation();
  const heroImage = t('feature.images.home');

  return (
    <div className="hero-section d-flex align-items-center">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6 text-center text-md-left hero-text">
            <h1>{t('welcome')}</h1>
            <p>{t('feature.herosection-title')}</p>
            <div className={`btn-container ${buttonSize}`}>
              <a className="btn btn-primary" href={buttonLink} target="_blank" rel="noopener noreferrer">
                <img src={buttonImage} alt="Button Icon" className="button-icon" />
              </a>
            </div>
          </div>
          <div className="col-md-6 text-center">
            <img src={heroImage} alt="Healthy Lifestyle" className="hero-image" style={{ width: imageWidth, height: imageHeight }} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;