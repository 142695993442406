// src/components/Privacy.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import './Privacy.css';

function Privacy() {
  const { t } = useTranslation();

  return (
    <div className="container mt-5 privacy-container">
      <h1>{t('privacy.title')}</h1>
      <p>{t('privacy.introduction')}</p>
      <h2>{t('privacy.section1.title')}</h2>
      <p>{t('privacy.section1.content')}</p>
      <h2>{t('privacy.section2.title')}</h2>
      <p>{t('privacy.section2.content')}</p>
      <h2>{t('privacy.section3.title')}</h2>
      <p>{t('privacy.section3.content')}</p>
      <h2>{t('privacy.section4.title')}</h2>
      <p>{t('privacy.section4.content')}</p>
      <h2>{t('privacy.section5.title')}</h2>
      <p>{t('privacy.section5.content')}</p>
      <h2>{t('privacy.section6.title')}</h2>
      <p>{t('privacy.section6.content')}</p>
      <h2>{t('privacy.section7.title')}</h2>
      <p>{t('privacy.section7.content')}</p>
    </div>
  );
}

export default Privacy;